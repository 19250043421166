<template>
  <div class="total">
    <div class="configure">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="组织架构" name="first">
          <div class="margin-tb">
            <!-- 学校和销售的添加 -->
            <el-row>
              <el-button type="primary" @click="editHandle(2,{},'新增')" size="small">新建组织</el-button>
            </el-row>
          </div>
          <el-table
            :data="organizationList"
            style="width: 100%;margin-top: 20px;"
            row-key="id"
            border
            lazy
            :load="load"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            v-loading="loading"
			ref="singleTable"
          >
            <el-table-column label="名称" width="260">
              <template slot-scope="scope">
                <span class="lesson-tag" v-if="scope.row.type==-1">总</span>
                <span class="class-tag" v-if="scope.row.type==4">区</span>
                <span class="type-tag" v-if="scope.row.type==1">校</span>
                <span class="price-tag" v-if="scope.row.type==2">部</span>
                <span class="margin-left-xs">{{scope.row.name}}</span>
              </template>
            </el-table-column>

            <el-table-column prop="sortnum" label="校区排序" width="180"></el-table-column>
            <el-table-column label="校区类型" width="180">
              <template slot-scope="scope">{{scope.row.school_type | schoolTypeFilter}}</template>
            </el-table-column>
            <el-table-column prop="phoneone" label="电话1" width="150"></el-table-column>
            <el-table-column prop="phonetwo" label="电话2" width="150"></el-table-column>
            <el-table-column prop="path" label="地址"></el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{row,$index}">
                <span @click="editHandle(2,row,'新增')" class="text-blue cursor">添加</span>

                <!-- 学校的编辑 -->
                <span class="text-blue cursor margin-left" @click="editHandle(4,row,'编辑')">编辑</span>

                <!-- 部门的删除 -->
                <span class="text-blue cursor margin-left" @click="Delete(row.id,$index,row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="datacount"
        style="text-align: center;margin-top: 10px;"
        @next-click="prevClick"
        @prev-click="prevClick"
        @current-change="prevClick"
      ></el-pagination>
    </div>

    <!-- 学校———编辑和添加 -->
    <OrganizationCampus
      :Visible="editVisibles"
      :detail="organizationDetail"
      @closepop="closeEditPop"
      @close="Close"
    ></OrganizationCampus>

    <el-dialog
      title="删除"
      :visible.sync="delorganization"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="20%"
    >
      <div class="padding text-center">
        <span>确认删除该层级？</span>
      </div>
      <div class="flex justify-content-center">
        <el-button class="myClient-left-30" @click="delorganization = !delorganization">取消</el-button>
        <el-button type="primary" @click="changes">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { fileType } from "@/config/index";
import OrganizationCampus from "../compinents/OrganizationCampus.vue";
export default {
  components: {
    OrganizationCampus
  },
  data() {
    return {
      datacount: 0,
      loading: true,
      editVisible: false,
      editVisibles: false,
      editType: 1,
      ids: "",
      index: "",

      organizationDetail: {
        //传给学校添加和编辑的
        num: "",
        title: "",
        Message: ""
      },
	  delrow:null,
      activeName: "first",

      delorganization: false,
      organizationList: [], //组织列表
      listQuery: {
        ParentId: -1,
        type: -1,
        isM: true,
        hasUser: true
      }
    };
  },

  created() {
    this.OrganizationList();
  },
  methods: {
    // 修改数据后重新加载节点
    reloadNode(id) {
      const { lazyTreeNodeMap, treeData } = this.$refs.singleTable[
        "store"
	  ].states; 
        this.$set(lazyTreeNodeMap, id, []);

        this.load({ id: id }, null, data => {
        this.$set(treeData[id], "loading", false);
        this.$set(treeData[id], "loaded", true);
        this.$set(treeData[id], "expanded", true);
        if (data.length) {
          this.$set(lazyTreeNodeMap, id, data);
        } else {
          treeData[id].hasChildren = false;
        }
      });
    },
    OrganizationList() {
		debugger;
      this.organizationList = [];
      this.API.OrganizationGetTree(this.listQuery).then(res => {
        this.loading = false;
        this.datacount = res.length;
        this.organizationList = res;
        this.organizationList.forEach(item => {
          if (item.issubordinate) {
            item.hasChildren = true;
          }
        });
      });
    },

    //学校
    editHandle(type, detail, name) {
	  this.editVisibles = true; 
      if (detail != "") {
        this.organizationDetail.Message = detail;
      }

      this.organizationDetail.title = name;
      this.organizationDetail.num = type;
    },
    Delete(id, index,row) {
      this.ids = id;
      this.index = index;
	  this.delorganization = true; 
	  this.delrow=row;
    },
    Close() {
      this.editVisibles = false;
	  this.editVisible = false;
	  var d = this.organizationDetail.Message;  
	  if(d){
		  this.reloadNode(this.organizationDetail.num==2?d.id:d.ParentId);
	  }
    //   this.OrganizationList();
    },
    closeEditPop() {
      this.editVisibles = false;
      this.editVisible = false;
    },
    changes() {
      this.API.OrganizationGetChangestatus(this.ids).then(res => {
        this.$message.success("删除成功");
        this.delorganization = false;
		// this.OrganizationList();
			  this.reloadNode(this.delrow.ParentId); 
      });
    },
    load(tree, treeNode, resolve) {
      setTimeout(() => {
        let changetype = {};
        changetype.ParentId = tree.id;
        // if(tree.type==-1){
        // 	changetype.type=4,
        // 	changetype.hasUser=true
        // }else if(tree.type==4){
        // 	changetype.type=1,
        // 	changetype.hasUser=true
        // }else if(tree.type==1){
        // 	changetype.hasUser=true
        // 	changetype.type=2
        // }
        this.API.OrganizationGetTree(changetype).then(res => {
          res.forEach(item => {
            if (item.issubordinate) {
              item.hasChildren = true;
            }
          });
          resolve(res);
        });
      }, 1000);
    },
    prevClick(val) {
		debugger;
      this.listQuery.PageIndex = val;
      this.OrganizationList();
    }
  }
};
</script>

<style lang="scss">
.color- {
  color: #8cc5ff;
}

.color-box {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}

.total {
  width: 100%;
}

.TopLabel {
  widows: 200px;
}

.TopLabel_1 {
  widows: 300px;
}

.configure {
  width: 100%;
  background-color: #fff;

  padding: 30px 10px 10px 10px;
}
</style>
