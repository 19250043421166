<template>
	<!-- 校区的编辑 -->
	<el-dialog :title="detail.title" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="40%">
		<div class="myClient-top-10 padding">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="上级">
					<el-col :span="12">
						<div @click='editHandle' class="border-gray border-r" style="height: 40px;">
							{{meaagesNames}}
							<chooseSuperior :Visible="editVisible" @closepop="closeEditPop" v-if='detail.num==4'
								@superiorData="superiorData">
							</chooseSuperior>
						</div>
					</el-col>
				</el-form-item>
				<el-form-item label="类型">
					<el-radio v-model="ruleForm.type" :label='4' :disabled="num==4?true:false">片区</el-radio>
					<el-radio v-model="ruleForm.type" :label='1' :disabled="num==4?true:false">校区</el-radio>
					<el-radio v-model="ruleForm.type" :label='2' :disabled="num==4?true:false">部门</el-radio>
				</el-form-item>
				<el-form-item label="名称" prop="name">
					<el-input v-model="ruleForm.name" maxlength="10" placeholder="最多十个字" />
				</el-form-item>
				<el-form-item label="电话1">
					<el-input v-model="ruleForm.phoneone" />
				</el-form-item>
				<el-form-item label="电话2">
					<el-input v-model="ruleForm.phonetwo" />
				</el-form-item>
				<el-form-item label="地址">
					<el-input v-model="ruleForm.path" />
				</el-form-item>
				<el-form-item label="排序" v-if="ruleForm.type==1">
					<el-col :span="6">
						<el-input v-model="ruleForm.sortnum"></el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="校区类型" prop="school_type" v-if="ruleForm.type==1">
					<el-select v-model="ruleForm.school_type" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
							{{item.value | schoolTypeFilter}}
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>
		<div class="flex justify-content-center">
			<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
			<el-button class="myClient-left-30" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import chooseSuperior from '../compinents/chooseSuperior'
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			},
			detail: {
				type: Object,
				default: ()=>{
					return {}
				},
			},
			
		},
		components: {
			chooseSuperior
		},
		data() {
			return {
				options: [{
					value: 1,
					label: '直营'
				}, {
					value: 2,
					label: '合作'
				}, {
					value: 3,
					label: '加盟'
				}, {
					value: 4,
					label: '代理'
				}],
				ParentId:0,
				dialogVisible: false,
				num:0,
				ruleForm: {
					type: 4,
					sortnum: 0,
					ParentId: 0,//属于“戴氏精品堂”下面的片区
				},
				meaagesNames: '',
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
					school_type: [{
						required: true,
						message: "请选择校区类型",
						trigger: "change"
					}],
				},
			};
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
				this.num=this.detail.num
				if (this.detail.num == 4) {
					this.ruleForm={};
					this.ParentId=this.detail.Message.ParentId
					this.getOrganizationGetbyid()
					// this.meaagesNames="戴氏精品堂";
					// this.SuperiorMessage(this.detail.Message)
					this.ruleForm = this.detail.Message
					console.log(this.ruleForm)
				} else {
					this.ruleForm.ParentId=this.detail.Message.id
					this.meaagesNames = this.detail.Message.name
				}
			},
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.ruleForm={
					type: 4,
					sortnum: 0,
					ParentId: 0,
					name:'',
					path:'',
					phoneone:'',
					phonetwo:'',
				}
				this.$emit("closepop");
			},
			//根据ParentId查询对应的组织
			getOrganizationGetbyid(){
				this.API.OrganizationGetbyid(this.ParentId).then(res=>{
					console.log(res)
					this.meaagesNames=res.name
				})
			},
			superiorData(val) {
				this.meaagesNames = val.name
				this.ruleForm.ParentId = val.id
			},
			submitForm(formName) { 
				if (!this.ruleForm.name) {
					this.$message.error("请填写名称")
				} else if (!this.ruleForm.school_type && this.ruleForm.type === 1) {
					this.$message.error("请选择类型")
				} else {
					console.log(this.ruleForm)
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.API.OrganizationCreateorupdate(this.ruleForm).then(res => {
								this.$message.success("操作成功")
								this.ruleForm={
									type: 4,
									sortnum: 0,
									ParentId: 0,
									name:'',
									path:'',
									phoneone:'',
									phonetwo:'',
								}
								this.$emit("close");
							})
						} else {
							return false;
						}
					});
				}

			},
		},
	};
</script>

<style>
	.text-align {
		text-align: center;
	}

	.active {
		background-color: #f5f5f5;
	}

	.height {
		height: 40px;
	}

	.status-point {
		background-color: #409eff;
		margin-right: 5px;
		display: inline-block;
		width: 6px;
		height: 6px;
		border-radius: 50%;
	}
</style>
